<template>
  <div class="container has-background-white-ter">
    <div class="columns" v-if="mode == 'start'">
      <div class="column is-8 is-offset-2 is-size-2 has-text-centered">
        <QrcodeStream style="max-height: 50vh">
          <h4 class="has-text-warning">Please scan your QR</h4>
        </QrcodeStream>
      </div>
    </div>
    <div class="columns" v-else>
      <div class="column is-one-half">
        <div class="card card-equal-height">
          <div class="card-content">
            <div class="content is-uppercase is-size-2 has-text-centered">
              <a href="#" @click="switchMode('haveQR')" class="stretched-link"
                ><strong>I HAVE</strong> a QR Code</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-half">
        <div class="card card-equal-height">
          <div class="card-content">
            <div class="content is-uppercase is-size-2 has-text-centered">
              <a href="#" @click="switchMode('noQR')" class="stretched-link"
                ><strong>I DO NOT HAVE</strong> a QR Code</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
//import api from 'helpers/apicalls';
//import { format } from 'date-fns'
import { QrcodeStream } from "vue-qrcode-reader";

//var instance = null;

export default {
  name: "visitor-qr",

  data: function () {
    return {
      mode: "start",
    };
  },

  methods: {
    switchMode(mode) {
      this.mode = mode;
      this.$store.commit("interaction");
    },
    onDecode(decodedString) {
      this.$store.commit("interaction");
      alert(decodedString);
      // ...
    },
  },
  components: {
    QrcodeStream,
  },

  created() {
    //instance = this;
  },

  mounted() {
    //this.$store.commit('hideNav')
    //instance = this;
    //this.showTime();
    this.mode = "start"
  },
};
</script>

<style scoped>
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-equal-height .card-footer {
  margin-top: auto;
}
</style>

<template>
  <div class="container has-background-white-ter">
    <VisitorQR v-if="mode=='haveQR'"></VisitorQR>
    <VisitorNoQR v-else-if="mode=='noQR'"></VisitorNoQR>
    <div class="columns" v-else>
      <div class="column is-one-half">
        <div class="card card-equal-height">
          <div class="card-content">
            <div class="content is-uppercase is-size-2 has-text-centered">
              <a href="#" @click="switchMode('haveQR')" class="stretched-link" ><strong>I HAVE</strong> a QR Code</a>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-half">
        <div class="card card-equal-height">
          <div class="card-content">
            <div class="content is-uppercase is-size-2 has-text-centered">
               <a href="#" @click="switchMode('noQR')" class="stretched-link" ><strong>I DO NOT HAVE</strong> a QR Code</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
//import api from 'helpers/apicalls';
//import { format } from 'date-fns'

//var instance = null;

import VisitorNoQR from "./VisitorNoQR.vue"
import VisitorQR from "./VisitorQR.vue"

export default {
  name: "home",




  data: function() {
    return {
      mode: 'start',
      wibble: 'xyz'
    };
  },
  methods: {
    switchMode(mode) {
      this.mode = mode;
      this.$store.commit("interaction")

    },
    onDecode(decodedString) {
      this.$store.commit("interaction")
      this.wibble = decodedString;
    // ...
  },








  },
components: {
    VisitorNoQR,
    VisitorQR

  },

  created() {
    //instance = this;
  },

  mounted() {
    //this.$store.commit('hideNav')
    //instance = this;
    //this.showTime();
  }



};
</script>

<style scoped>

.stretched-link::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:1;
  content:""
}

.card-equal-height {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.card-equal-height .card-footer {
  margin-top: auto;
}

</style>

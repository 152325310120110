<template>
  <div class="container has-background-white-ter">
    <input
      :value="input"
      class="input "
      placeholder="Tap on the virtual keyboard to start"
    />

    <div class="simple-keyboard"></div>
  </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import autocorrect from "simple-keyboard-autocorrect";

// CSS


//Vue.use(VueTouchKeyboard);

//var instance = null;

let wordList = ["isaac", "ackland", "david", "adams", "lisa", "clare", "allen", "sian", "phillipa", "anthony", "samantha", "armstrong", "parents", "association", "penelope", "beeby", "debbie", "bell", "sandra", "berry", "kim", "blakeway", "heather", "bowden", "chris", "caws", "joyce", "clarke", "wendy", "karen", "clucas", "kaz", "alan", "daniel", "paul", "darmanin", "susan", "ann", "devereux", "rachel", "firth", "kathryn", "forsdike", "katie", "frampton", "rebecca", "froud", "beki", "judi", "galbraith", "sarah", "gay", "rebekah", "griffin", "becky", "hackston", "harriet", "mike", "hamilton", "hampton", "matthew", "hastings", "penny", "esther", "hatcher", "emily", "hawley", "lydia", "house", "amy", "howard", "tamaryn", "kemp", "alison", "kinchin", "fred", "judith", "kirkland", "steven", "knapp", "gillian", "knowles", "harun", "kotch", "kwan pui novem", "kwong (lee)", "novem", "laker", "sue", "daphne", "land-jones", "belinda", "langmead", "lorrie", "lawrenson", "angela", "lemon", "hannah", "mole", "robert", "mullane", "rob", "dasha", "murdock", "elizabeth", "ngero", "hayley", "osborn", "emma", "osborne", "caroline", "pancott", "parker", "pattinson", "robyn", "pearson", "samuel", "sam", "brian", "perry", "melanie", "melaine", "victoria", "prow", "louise", "puckett", "john", "roberts", "paula", "robinson", "philip", "roseblade", "santer", "ali", "scurr", "skerrett", "smith", "richard", "stannard", "stride", "marie", "taylor", "thompson-kenny", "nathan", "thorpe", "helen", "trevett", "lorraine", "anna", "vale", "dan", "vardy", "dawn", "jenny", "walker", "margaret", "lesley", "walter", "webb", "laura", "weller", "white", "carolyn", "williams", "marlene", "wilson", "wolpe", "wong", "andrew", "worsfold", "xxx", "janina"]

export default {
  name: "visitor-no-qr",

  data() {
    return {
      keyboard: null,
      visible: false,
      input: "",
      
    };
  },
  mounted() {
    this.keyboard = new Keyboard("simple-keyboard", {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      autocorrectDict: wordList,
      autocorrectHotkey: "{space}",
      modules: [autocorrect],
      layout: {
  'default': [
    '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    '{tab} q w e r t y u i o p [ ] \\',
    '{lock} a s d f g h j k l ; \' {enter}',
    '{shift} z x c v b n m , . / {shift}',
    '.com @ {space} .uk'
  ],
  'shift': [
    '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
    '{tab} Q W E R T Y U I O P { } |',
    '{lock} A S D F G H J K L : " {enter}',
    '{shift} Z X C V B N M < > ? {shift}',
    '.com @ {space} .uk'
  ]
}
    });
  },

  methods: {
    onInputChange(input) {
      this.input = input.target.value;
    },

    onChange(input) {
      this.input = input;
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
      //console.log(button);
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>
<style scoped>
</style>
